import { navigate } from "gatsby-link"
import React, { useEffect } from "react"

const BlogDetailsPage = () => {
  useEffect(() => {
    navigate("/blog")
  }, [])

  return <></>
}

export default BlogDetailsPage
